const FORM_INPUT_CLASS_MAP = {
    number: "number-input",
    text: "text-input",
    email: "text-input",
    textarea: "textarea",
    date: "date-input",
    "datetime-local": "date-input",
    select: "select",
    checkbox: "checkbox-input",
    url: "url-input",
}

export function getElementClass(context, classes) {
    let inputType = context.type;
    return classes.concat([FORM_INPUT_CLASS_MAP[inputType]])
}

export function getWrapperClass(context, classes) {
    let hasHelpTextBelow = context.help && context.helpPosition === 'after';
    let wrapperClass = context.hasErrors || hasHelpTextBelow ? '' : 'mb-2';
    return classes.concat([wrapperClass])
}
