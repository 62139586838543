export var loadingMixin = {
  data() {
    return {
      loading: false,
      loadingError: false,
    }
  },
  methods: {
    startLoading() {
      this.loading = true;
    },
    stopLoading() {
      this.loading = false;
    },
    loadingErrorOccurred() {
      this.loadingError = true;
    },
    somePromisesRejected(values) {
      let statusList = values.map(value => value.status);
      return statusList.some(status => status === 'rejected')
    },
    handleLoadingComplete(values) {
      setTimeout(() => {
        this.stopLoading();
        if (this.somePromisesRejected(values)) {
          this.loadingErrorOccurred();
        }
      }, 300);
    },
  }
}
