export var formDataMixin = {
  props: {
    instanceData: {
      type: Object,
      default: () => {}
    },
    formErrors: {
      type: Object,
      default: () => {}
    },
    editing: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      formData: this.instanceData,
      emptyOption: {
        label: '-----',
        value: null,
      }
    }
  },
  methods: {
    saveInstance() {
      this.$emit('save-instance', this.formData);
    },
    cancelForm() {
      this.$emit('cancel-form');
    },
    deleteInstance() {
      this.$emit('delete-instance', this.formData);
    }
  },
}
