<template>
    <div class="loading-container" :class="loadingDisplayClass">
        <div class="loading-indicator"></div>
        <div class="loading-text">
            <slot>
              Loading...
            </slot>
        </div>
    </div>
</template>

<script>
export default {
  name: 'LoadingIndicator',
  props: {
    displayType: {
      type: String,
      default: "stacked"
    },
  },
  computed: {
    loadingDisplayClass () {
      return `display-${this.displayType}`;
    },
  },
}
</script>
