<template>
  <a href="#" @click="toggleFAQOpenState()">
    ECoach Help
    <Teleport to="#slotWrapper">
      <faq-modal />
    </Teleport>
  </a>
</template>

<script>

import { mapActions } from 'pinia';
import { useFaqStore } from '../../stores/faq.js';

import FAQModal from './FAQModal.vue';

export default({
  name: 'FAQMenuItem',
  data () {
    return {};
  },
  components: {
    'faq-modal': FAQModal,
  },
  methods: {
    ...mapActions(useFaqStore, [
      'toggleFAQOpenState'
    ]),
  },
});
</script>
