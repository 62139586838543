<template>
    <div class="modal-contain" :class="modalClasses">
        <div class="backdrop" @click="$emit('modal-cancel')"></div>
        <div class="modal active">
            <div class="modal-content">
                <slot name="wrapper-modal-header"></slot>
                <slot></slot>
                <slot name="wrapper-modal-footer"></slot>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'Modal',
  props: ['modalHeader', 'modalClasses', 'backdrop'],
}

</script>
