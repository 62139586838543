<template>
    <div class="FAQ-list">
        <div class="modal-header">
            <button
                class="btn modal-back"
                type="button"
                aria-label="Back to FAQs"
                v-if="displayEmailStatus"
                @click="displayEmailMode(displayEmailStatus)">
                <svg class="modal-icon icon-navy">
                    <use :xlink:href="backIcon"></use>
                </svg>
            </button>
            <h2>ECoach Help</h2>
            <button
                class="btn modal-close"
                type="button"
                aria-label="Close ECoach Help modal"
                @click="toggleFAQOpenState()">
                <svg class="modal-icon icon-navy">
                    <use :xlink:href="exitIcon"></use>
                </svg>
            </button>
        </div>
        <form class="support-email survey" v-if="displayEmailStatus || !FAQListData.length">
            <h3 v-if="!FAQListData.length">In need of ECoach help? <b>Contact us.</b></h3>
            <h3 v-else>Contact us</h3>
            <div class="survey_question">
                <label class="survey_prompt" for="subject">Subject</label>
                <div class="survey_responses">
                    <textarea id="subject" v-model="emailSubject" ref="subject"></textarea>
                </div>
            </div>
            <div class="survey_question">
                <label class="survey_prompt" for="message">Message</label>
                <div class="survey_responses long-response">
                    <textarea id="message" v-model="emailInput"></textarea>
                </div>
            </div>
            <div class="button-block">
                <button class="btn" type="submit" @click="dispatchEmail" value="sendEmail">Submit</button>
            </div>
        </form>
        <template v-else>
            <div class="content">
                <p>In need of ECoach help? Review the FAQs below to get the answers you need. <b>Don’t see your question here?</b></p>
            </div>
            <div class="button-block">
                <button class="btn"
                    @click="displayEmailMode(displayEmailStatus)">
                    Contact us
                </button>
            </div>
            <h3>FAQs</h3>
            <div class="content">
                <div class="accordion vue-accordion">
                    <button
                        class="accordion-group"
                        :class="dynamicClass(FAQ)"
                        type="button"
                        v-for="(FAQ, index) in FAQListData"
                        :key="FAQ.id"
                        :ref="`FAQ${index}`"
                        @click="toggleAccordion(FAQ)">
                        <h3>
                            {{FAQ.question}}
                            <span class="ui-accordion-header-icon"></span>
                        </h3>
                        <div class="accordion-content" v-html="FAQ.answer"></div>
                    </button>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import { mapState, mapActions } from 'pinia';
import { useFaqStore } from '../../stores/faq.js';

import { exitIcon, backIcon } from "../../images.js";

export default({
    name: 'FAQList',
    data () {
      return {
        openAccordions: [],
        emailSubject: '',
        emailInput: '',
        exitIcon: exitIcon,
        backIcon: backIcon,
      };
    },
    methods: {
      ...mapActions(useFaqStore, [
        'sendEmailData',
        'setEmailStatus',
        'toggleFAQOpenState',
        "sendEmail",
        "loadFAQData",
      ]),
      dynamicClass: function (FAQ) {
        return { active: this.openAccordions.includes(FAQ) };
      },
      toggleAccordion (FAQ) {
        this.openAccordions.includes(FAQ) ? this.openAccordions.pop(FAQ) : this.openAccordions.push(FAQ);
      },
      displayEmailMode: function() {
        this.setEmailStatus();
      },
      dispatchEmail: function () {
        this.sendEmail({
          subject: this.emailSubject,
          email_content: this.emailInput
        }).then((response) => {
          this.resetModal();
          this.toggleFAQOpenState()
        });
      },
      resetModal: function () {
        this.emailSubject = '';
        this.emailInput = '';
      },
      closeAlert: function () {
        this.sendEmailData('');
      },
      focusInput(){
        this.FAQListData.length ? this.$refs["FAQ0"][0].focus() : this.$refs.subject.focus();
      },
    },
    computed: {
      ...mapState(useFaqStore, [
        'FAQListData',
        'displayEmailStatus',
        'sentEmail',
      ]),
    },
    mounted: function () {
      this.loadFAQData().then(() => {
        this.focusInput();
      });
    }
});
</script>
